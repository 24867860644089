.modal-enter {
  opacity: 0;
  transform: scale(0.9);
}

.modal-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.modal-exit {
  opacity: 1;
  transform: scale(1);
}

.modal-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/* Animation de flottement */
@keyframes floating {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

/* Application de l'animation sur le bouton */
.btn-floating {
  display: inline-block;
  animation: floating 2s ease-in-out infinite;
  /* Animation en boucle */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

/* L'ombre devient plus forte au survol */
.btn-floating:hover {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

/* Animation de flottement pour l'image */
@keyframes floatingImage {
  0% {
    transform: scale(1) translateY(0);
  }

  50% {
    transform: scale(1.05) translateY(-5px);
  }

  100% {
    transform: scale(1) translateY(0);
  }
}

/* Application de l'animation sur l'image */
.hero-image img {
  animation: floatingImage 4s ease-in-out infinite;
  will-change: transform;
  /* Optimisation du rendu */
}

.hero {
  height: 100vh;
  display: flex;
  align-items: center;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.534) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.btn-success {
  display: inline-block;
  /* margin: 10px; */
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border-radius: 5px;
  text-decoration: none;
}