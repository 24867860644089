/* FAQSection.css */
.accordion-collapse {
    transition: max-height 0.3s ease-in-out;
}

.accordion-collapse.collapse {
    max-height: 0;
    overflow: hidden;
}

.accordion-collapse.show {
    max-height: 200px;
    /* ajustez la valeur selon votre contenu */
}