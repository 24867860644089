.call-to-action {
    position: relative;
    padding: 5px 0;
    border-radius: 20px;
    background-color: #fbfbfb;
    overflow: hidden; /* Cache les éléments en dehors de la zone visible */
  }

  .cta-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    align-items: center;
    gap: 2px; /* Espacement entre les lignes de logos */
  }
  
  .logo-line {
    display: flex;
    gap: 170px; /* Espacement entre les logos */
    animation-duration: 30s; /* Durée totale de l'animation */
    animation-timing-function: linear;
    animation-iteration-count: infinite; /* Animation infinie */
    white-space: nowrap; /* Empêche les logos de se placer sur plusieurs lignes */
  }
  
  .scroll-right-to-left {
    animation-name: scrollRightToLeft;
  }
  
  .scroll-left-to-right {
    animation-name: scrollLeftToRight;
  }

  .logo {
    flex-shrink: 0;
    display: inline-block;
  }

  /* Réduire la taille de l'image directement */
  .logo img {
    width: 80px; /* Taille de l'image */
    height: auto; /* Garder les proportions de l'image */
  }

  /* Animation de défilement de droite à gauche */
  @keyframes scrollRightToLeft {
    0% {
      transform: translateX(100%); /* Début à droite */
    }
    50% {
      transform: translateX(0); /* Milieu de l'animation */
    }
    100% {
      transform: translateX(-100%); /* Fin à gauche */
    }
  }
  
  /* Animation de défilement de gauche à droite */
  @keyframes scrollLeftToRight {
    0% {
      transform: translateX(-100%); /* Début à gauche */
    }
    50% {
      transform: translateX(0); /* Milieu de l'animation */
    }
    100% {
      transform: translateX(100%); /* Fin à droite */
    }
  }
  