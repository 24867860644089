/* Gallery.css */


.filter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  gap: 30px; /* Ajoutez un espace entre les cartes */
}

.filter-container.fade-out {
  opacity: 0;
  transform: scale(0.1);
}

.filter-container.fade-in {
  opacity: 1;
  transform: scale(1);
}
.image-card {
  border: 2px solid rgb(22, 2, 175); /* Bordure verte */
  border-radius: 8px; /* Coins arrondis */
  padding: 0px; /* Espacement autour de l'image */
  transition: border-color 0.3s ease; /* Transition douce pour la bordure */
  position: relative;
  overflow: hidden;
}
.img-fluid1{
  border-radius: 8px; /* Coins arrondis */

}
.image-card:hover {
  border-color: rgb(2, 165, 2); /* Changement de couleur de bordure au survol */
}

/* Portfolio Section CSS */

.image-card img {
  transition: transform 0.3s ease;
  border-radius: 8px;
  width: 100%;
}

.image-card:hover img {
  transform: scale(1.1); /* Zoom effect */
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 100%;
  height: 100%; */
  background: rgba(0, 0, 0, 0.5) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  text-align: center;
}

.image-card:hover .image-overlay {
  opacity: 1;
}

.image-overlay span {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.divider {
  width: 50%;
  border-top: 2px solid #fff;
  margin: 10px 0;
}

.image-overlay i {
  font-size: 24px;
  color: #fff;
  margin-top: 10px;
  animation: bounce 1s infinite;
}

/* Animation for the icon */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.4s ease;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.4s ease;
}
