/* Superposition pour fermer la lightbox */
.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8) !important;
  cursor: pointer;
  z-index: 9998;
}

/* Contenu principal */
.lightbox-content {
  position: fixed;
  top: 10%;
left: 10%;
right: 10%;
  /* transform: translate(-50%, -50%); */
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  z-index: 9999;
  padding: 0px;
  border-radius: 10px;
}

/* Image affichée */
.lightbox-content img {
  max-width: 100%;
  max-height: 70vh;
  width: auto;
  height: auto;
  object-fit: contain;
  transition: all 0.5s ease-in-out;
}

/* Légende sous l'image */
.lightbox-caption {
  margin-top: 10px;
  color: #fff;
  font-size: 16px;
}

/* Bouton précédent et suivant */
.lightbox-prev,
.lightbox-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: rgb(12, 233, 0);
  font-size: 30px;
  cursor: pointer;
  z-index: 10000;
}

/* Position des boutons précédent et suivant */
.lightbox-prev {
  left: 5px;
}

.lightbox-next {
  right: 5px;
}

.lightbox-prev:hover,
.lightbox-next:hover {
  color: #ddd;
}

/* Loader */
.loader-containerl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

/* Animation de transition */
.lightbox-image {
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.lightbox-image.fade {
  opacity: 0;
}

/* Media query pour les petits écrans (mobile) */
@media (max-width: 768px) {
  .lightbox-content {
    max-width: 100%;  /* Prend toute la largeur */
    max-height: 100%; /* Prend toute la hauteur */
    padding: 0px;    /* Réduit l'espace autour de l'image */
  }
  
  .lightbox-content img {
    width: 100%;      /* Prend toute la largeur */
    height: 100vh;    /* Prend toute la hauteur de l'écran */
    object-fit: contain; /* Garde les proportions tout en remplissant l'espace */
  }
  .lightbox-image {
    max-width: 50%;
    max-height: 70vh;
    object-fit: contain;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
  }
  
  .lightbox-prev, .lightbox-next {
    font-size: 24px;  /* Réduit la taille des icônes de navigation */
  }

  .lightbox-caption {
    font-size: 14px;  /* Réduit la taille de la légende */
  }
}
