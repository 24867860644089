.img-fluid {
  max-width: 100% !important;
  height: auto !important;
}
/* Loader.css */
.loader-containerh {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100vh; /* Prend toute la hauteur de la vue */
  flex-direction: column;
}

.loaderh {
  position: absolute; /* Position absolue pour tourner autour du logo */
  width: 120px;
  height: 120px;
  border: 4px solid transparent; /* Bordure transparente */
  border-top: 4px solid #03ed13; /* Couleur de l'animation */
  border-right: 4px solid #03a51087; /* Couleur secondaire pour design */
  border-radius: 50%;
  animation: spin 1.5s linear infinite; /* Animation de rotation */
  z-index: 1; /* Sous le logo */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader-containerh p {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #3498db; /* Blue */
}
